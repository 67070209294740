/* You can add global styles to this file, and also import other style files */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";
@import 'material-icons/iconfont/material-icons.css';

/* PrimeFlex */
@import "primeflex/primeflex.scss";

body {
  font-family: var(--font-family);
}

.aligned-quantity {
  text-align: right !important;
  padding-right: 1rem !important;
}

.bad {
  color: red;
  font-weight: bold;
}

.good {
  color: green;
}

.todo {
  color: orange;
  font-style: italic;
}

p-button.tiny button {
  padding: 0 0 0 0.3rem !important;
  width: auto !important;
}

tr.missing-parcels {
  background-color: #fff87b !important;
}
tr.accepted {
  background-color: #bdf578 !important;
}
.input-required {
  background-color: #ffa500 !important;
}

.big-loading-icon {
  font-size: 6rem !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color:  #aac0ff;
}

.p-fieldset .p-fieldset-legend {
  padding: 0.25rem;
  border: none;
  color: #343a40;
  background: none;
  font-weight: 600;
  border-radius: 6px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0.25rem;
  color: #343a40;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.cancel-disabled-effects-for-last-tab ul.p-tabview-nav li.p-disabled:nth-last-child(2) {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.p-datatable.p-datatable-xs .p-datatable-header {
  padding: 0.2rem 0.2rem;
}
.p-datatable.p-datatable-xs .p-datatable-thead > tr > th {
  padding: 0.2rem 0.2rem;
}
.p-datatable.p-datatable-xs .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.2rem;
}
.p-datatable.p-datatable-xs .p-datatable-tfoot > tr > td {
  padding: 0.2rem 0.2rem;
}
.p-datatable.p-datatable-xs .p-datatable-footer {
  padding: 0.2rem 0.2rem;
}

.pi-search-minus-xs {
  font-size: 1rem;
}

.p-breadcrumb-lg {
  background: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 1rem;
  font-size: 1rem
}

// see https://tobiasahlin.com/blog/flexbox-break-to-new-row/
.break {
  flex-basis: 100%;
  height: 0;
}

.panel-header-white .p-panel .p-panel-header {
  background: #ffffff;
}

.bulk-movements-inactive .p-panel .p-panel-header,
.bulk-movements-inactive .p-panel .p-panel-content {
  background: transparent;
  border: 0;
  padding: 0;
}
